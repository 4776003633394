import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Manifesto from "../components/manifesto"
import Brands from "../components/brands"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Manifesto />
    <Brands />
  </Layout>
)

export default IndexPage
