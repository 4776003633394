import { Link } from "gatsby"
import React from "react"

import styled from "styled-components"

import LogoBow from "./../images/brands/booksonwall.png"
import LogoAec from "./../images/brands/animaec.png"
import LogoP113 from "./../images/brands/p113.png"

const Logo = styled.img`
  width: 100%;
  margin: 0 auto;
  padding: 0  30px;
  max-width: 380px
`;
const Wrapper = styled.div`
  with: 100vw;
  padding: 0 0 92px
`;
const Container = styled.div`
 background: transparent;
 max-width: 1920px;
 margin: 0 auto;
`;
const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  width: 80vw;
  max-width: 1920px;
  margin: 0 auto;
`;
const Item = styled.div`
  width: 27%;
  background: transparent;
  margin: 15px 2%;
  &:hover {
      background: RGBa(255,255,255,.5);
      border-radius: 100px;
  }
`;
const Title = styled.h5`
  with: 100vw;
  padding: 35px 20px 10px;
  text-align: center;
  text-transform: uppercase;
  color: #070021;
  font-weight: 800;
`;

const Brands = () => (
  <Container>
    <Title>Gestionamos las marcas</Title>
    <Grid>
      <Item>
        <Link style={{margin: '0 auto'}} to="https://www.animaespacio.org" alt="Ánima Gestión Cultural"> <Logo src={LogoAec} alt="Logo" /></Link>
      </Item>
      <Item>
        <Link style={{margin: '0 auto'}} to="https://www.booksonwall.art" alt="BooksOnWall"><Logo src={LogoBow} alt="Logo" /></Link>
      </Item>
      <Item>
        <Link style={{margin: '0 auto'}} to="https://www.p113.io" alt="P113"><Logo src={LogoP113} alt="Logo" /></Link>
      </Item>
    </Grid>
  </Container>
);

export default Brands;
