import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: 4vh auto;
  background: transparent;
`;
const Container = styled.div`
  margin: 0 auto;
  padding: 5vh 3vh 0;
  width: 80vw;
  min-Width: 300px;
  max-Width: 1280px;
  background: transparent;
`;
const Text = styled.p`
  margin: 30px 0;
  max-width: 800px;
  line-height: 1.7em;
  font-size: 1.2em;
`;
const Button = styled.a`
 text-transform: uppercase;
 font-size: .7em;
 font-weight: 600;
 color: #000;
 text-decoration: none;
 background: #f9f9f9;
 padding: 9px;
 &:hover {
    color: #333;
    background: #f6f6f6;
    border-radius: 4px
  }
`;

const Manifesto = () => (
<Wrapper>
  <Container>
    <Text>Somos un equipo de creativos con un espíritu cultural y activista, nos hemos asociado con el fin de crear proyectos mediante arte y tecnología. Queremos colaborar en el empoderamiento tecnológico y generar impacto social positivo.</Text>
    <Button href="about"component="button">Más información</Button>
  </Container>
</Wrapper>
);

export default Manifesto;
